import {APISettings} from "./config.js";

const IdealistaApiMethods = (function () {
    function login(userEmail) {
        return post('login', {
            'user_email': userEmail,
        });
    }

    function fetchReply(userEmail, conversationId, conversation) {
        const data = {
            'user_email': userEmail,
            'conversation_id': conversationId,
            'conversation': conversation,
        };
        return post('reply', data).then(response => response.reply);
    }
    function reportConcern(userEmail, conversationId) {
        const data = {
            'user_email': userEmail,
            'conversation_id': conversationId,
        };
        return post('report-concern', data);
    }

    function fetchConversationAnalysisSentiments(userEmail, conversation) {
        return post('conversation-analysis/sentiments', {
            'user_email': userEmail,
            'conversation': conversation,
        });
    }

    function fetchConversationAnalysisSummary(userEmail, conversation) {
        return post('conversation-analysis/summary', {
            'user_email': userEmail,
            'conversation': conversation,
        });
    }

    function fetchConversationAnalysisKeywords(userEmail, conversation) {
        return post('conversation-analysis/keywords', {
            'user_email': userEmail,
            'conversation': conversation,
        });
    }

    function post(endpoint, data) {
        // Ensure starts with /
        endpoint = endpoint.startsWith('/') ? endpoint : '/' + endpoint;

        return fetch(APISettings.baseURL + endpoint, {
            method: 'POST',
            headers: APISettings.headers,
            body: JSON.stringify(data),
        })
            .then(handleResponse);
    }

    function handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);

            if (!response.ok) {
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        });
    }

    return {
        reportConcern: reportConcern,
        fetchReply: fetchReply,
        fetchConversationAnalysisSentiments: fetchConversationAnalysisSentiments,
        fetchConversationAnalysisSummary: fetchConversationAnalysisSummary,
        fetchConversationAnalysisKeywords: fetchConversationAnalysisKeywords,
        login: login,
    };
})();

export default IdealistaApiMethods;


