<template>
  <div>

    <div v-show="askingForConfirmation">
      <p class="text-xs mt-4">
        ¿Seguro que deseas enviar la respuesta generada para su análisis?
      </p>

      <button class="text-xs mr-4 text-[#0f6cbd] hover:text-[#0f558c] hover:underline" @click="report">
        Confirmar
      </button>
    </div>

    <button
        v-show="!askingForConfirmation && !reportedActionDone"
        class="text-xs text-[#0f6cbd] my-4 py-2 hover:text-[#0f558c] hover:underline"
        @click="askForConfirmation" :disabled="reportingConcern">
        <span class="flex">
          <span class="mr-1">
            <svg xmlns="http://www.w3.org/2000/svg" class="fill-[#0f6cbd]" width="14" height="14" viewBox="0 0 24 24"><path d="M17 8.031l4-6.031h-16v-2h-2v24h2v-10h16l-4-5.969zm-12-4.031h12.273l-2.677 4.037 2.656 3.963h-12.252v-8z"/></svg>
          </span>
          <span>Reportar respuesta</span>
        </span>
    </button>

    <alert-message v-if="errorMessage" :message="errorMessage"></alert-message>

    <div v-show="reportedActionDone" class="my-4 py-2 text-xs">
      <div class="flex">
        <span class="mr-1">
          <svg clip-rule="evenodd" width="14" height="14" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m2.25 12.321 7.27 6.491c.143.127.321.19.499.19.206 0 .41-.084.559-.249l11.23-12.501c.129-.143.192-.321.192-.5 0-.419-.338-.75-.749-.75-.206 0-.411.084-.559.249l-10.731 11.945-6.711-5.994c-.144-.127-.322-.19-.5-.19-.417 0-.75.336-.75.749 0 .206.084.412.25.56" fill-rule="nonzero"/></svg>
        </span>
        <span>Reporte enviado</span>
      </div>
    </div>
  </div>
</template>

<script>
import IdealistaApiMethods from "../api/IdealistaApiMethods.js";
import AlertMessage from "./AlertMessage.vue";

export default {
  components: {AlertMessage},
  props: {
    userEmail: String,
    conversationId: String,
  },
  data() {
    return {
      reportedActionDone: false,
      reportingConcern: false,
      askingForConfirmation: false,
      errorMessage: null,
    }
  },

  methods: {
    askForConfirmation() {
      this.askingForConfirmation = true;
    },
    report() {
      this.askingForConfirmation = false;
      this.reportingConcern = true;
      this.errorMessage = null;
      IdealistaApiMethods.reportConcern(this.userEmail, this.conversationId)
          .then((replyMessage) => {
            this.reportingConcern = false;
            this.reportedActionDone = true;
          })
          .catch(error => {
            console.error('There was an error sending the report', error);
            this.errorMessage = 'There was an error sending the report';
            this.reportingConcern = false;
          });
    },
  },
}
</script>

