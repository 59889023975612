<template>
    <div class="bg-white mb-3 border">
        <div class="p-2">

            <h2 class="font-bold text-sm text-center mb-2 border-b pb-2">Palabras clave</h2>
            <ul class="flex flex-wrap justify-center gap-2 p-2" :class="{'blur-sm': showLoading}">
                <li v-for="keyword in keywords"
                    class="inline-block p-1 text-xs font-semibold text-gray-800"
                    :class="getRelevanceClass(keyword.relevance)">
                    {{ keyword.name }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import IdealistaApiMethods from "../api/IdealistaApiMethods.js";

export default {
    components: {},
    props: {
        userEmail: {
            type: String,
        },
        messages: {
            type: Array,
        },
    },
    data() {
        return {
            showLoading: true,
            keywords: this.getDefaultValues(),
        }
    },

    methods: {
        getRelevanceClass(relevance) {
            return {
                'bg-green-50': relevance === 'high',
                'bg-blue-50': relevance === 'medium',
                'bg-yellow-50': relevance === 'low',
                'bg-gray-50': relevance === 'null',
            }
        },
        getDefaultValues() {
            return [
                {relevance: 'null', name: 'cobertura hogares'},
                {relevance: 'null', name: 'seguro'},
                {relevance: 'null', name: 'lorem ipsum'},
                {relevance: 'null', name: 'dolor sit amet'}
            ];
        },
        fetchConversationAnalysis() {
            IdealistaApiMethods.fetchConversationAnalysisKeywords(this.userEmail, this.messages)
                .then((responseData) => {
                    this.keywords = responseData;
                    this.showLoading = false;
                })
        }
    },
    mounted() {
        this.fetchConversationAnalysis();
    }
}
</script>

