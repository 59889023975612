import {createApp} from 'vue';
import './style.css';
import App from './App.vue';
import Pusher from 'pusher-js';
import * as Sentry from "@sentry/vue";

window.pusherClient = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
    wsHost: import.meta.env.VITE_PUSHER_HOST,
    wsPort: import.meta.env.VITE_PUSHER_PORT,
    wssPort: import.meta.env.VITE_PUSHER_PORT,
    forceTLS: true,
    encrypted: true,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    cluster: 'mt1'
});

const app = createApp(App);

Sentry.init({
    app,
    dsn: "https://8f412979aafa1a2c3997074880c3ce48@o163591.ingest.us.sentry.io/4507526082592768",
    integrations: [],
});

app.mount('#app');

