<template>
  <div class="bg-white mb-3 border">
    <div class="p-4">
      <h2 class="font-bold text-sm text-center mb-4 border-b pb-2">Análisis de sentimentos</h2>
      <ul class="grid grid-cols-2 justify-center gap-x-2 gap-y-4" :class="{'blur-sm': showLoading}">
        <li v-for="sentiment in sentiments"
            class="inline-block rounded"
            :class="getPolarityClass(sentiment.polarity)">

          <div class="flex items-center">
            <span v-if="sentiment.polarity==='negative'">
              <svg class="fill-red-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path
                  d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm.001 14c-2.332 0-4.145 1.636-5.093 2.797l.471.58c1.286-.819 2.732-1.308 4.622-1.308s3.336.489 4.622 1.308l.471-.58c-.948-1.161-2.761-2.797-5.093-2.797zm-3.501-6c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5-.672-1.5-1.5-1.5zm7 0c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5-.672-1.5-1.5-1.5z"/></svg>
            </span>

            <span v-if="sentiment.polarity==='positive'">
              <svg class="fill-green-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path
                  d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.507 13.941c-1.512 1.195-3.174 1.931-5.506 1.931-2.334 0-3.996-.736-5.508-1.931l-.493.493c1.127 1.72 3.2 3.566 6.001 3.566 2.8 0 4.872-1.846 5.999-3.566l-.493-.493zm-9.007-5.941c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5-.672-1.5-1.5-1.5zm7 0c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5-.672-1.5-1.5-1.5z"/></svg>
            </span>

            <span v-if="sentiment.polarity==='neutral'">
              <svg class="fill-yellow-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                   viewBox="0 0 24 24"><path
                  d="M17 15.134l-.562-.742c-3.568 2.728-4.703-3.279-9.438 1.574l.641.771c3.664-3.036 4.726 3.144 9.359-1.603zm-5-13.134c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3.5 8c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5-.672-1.5-1.5-1.5zm7 0c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5-.672-1.5-1.5-1.5z"/></svg>
            </span>

            <span class="text-xs font-semibold text-gray-800 ml-2">
           {{ sentiment.name }}
          </span>
          </div>

        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import IdealistaApiMethods from "../api/IdealistaApiMethods.js";

export default {
  props: {
    userEmail: {
      type: String,
    },
    messages: {
      type: Array,
    },
  },
  data() {
    return {
      showLoading: true,
      sentiments: this.getDefaultValues(),
    }
  },

  methods: {
    getPolarityClass(polarity) {
      return '';
      return {
        'border border-red-500 bg-red-50': polarity === 'negative',
        'border border-yellow-500 bg-yellow-50': polarity === 'neutral',
        'border border-green-500 bg-green-50': polarity === 'positive',
      }
    },
    getDefaultValues() {
      return [
        {polarity: 'negative', name: 'Frustración'},
        {polarity: 'neutral', name: 'Neutro'},
      ];
    },
    fetchConversationAnalysis() {
      IdealistaApiMethods.fetchConversationAnalysisSentiments(this.userEmail, this.messages)
          .then((responseData) => {
            this.sentiments = responseData;
            this.showLoading = false;
          })
    }
  },
  mounted() {
    this.fetchConversationAnalysis();
  }
}
</script>

