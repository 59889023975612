import {PublicClientApplication} from "@azure/msal-browser";

const OutlookApiMethods = (function () {
    function fetchMessages(restUrl, accessToken) {
        return fetch(restUrl, {
            method: 'GET',
            headers: {'Authorization': `Bearer ${accessToken}`}
        })
            .then(handleResponse)
            .then(data => processFetchedMessages(data));
    }

    function restUrlMe(conversationId) {
        conversationId = encodeURIComponent(conversationId);
        return `https://graph.microsoft.com/v1.0/me/messages?$filter=conversationId eq '${conversationId}'&$select=uniqueBody,toRecipients,from,subject,sentDateTime,isDraft`;
    }

    function restUrlSharedMailbox(mailboxEmail, conversationId) {
        conversationId = encodeURIComponent(conversationId);
        return `https://graph.microsoft.com/v1.0/users/${mailboxEmail}/messages?$filter=conversationId eq '${conversationId}'&$select=uniqueBody,toRecipients,from,subject,sentDateTime,isDraft`;
    }

    async function fetchConversationMessages(accessToken, conversationId, userEmail, mailboxEmail) {
        let sharedMailbox = (userEmail !== mailboxEmail);
        if (mailboxEmail === 'seguros@idealista.com') {
            sharedMailbox = true;
        }

        let restUrl = (sharedMailbox) ? restUrlSharedMailbox(mailboxEmail, conversationId) : restUrlMe(conversationId);

        return fetchMessages(restUrl, accessToken)
            .then(messages => {
                if (messages.length > 0) {
                    return messages;
                }
                if (sharedMailbox) {
                    return messages;
                }
                // Cas excepcional: s'està gestionant un email des de seguros@idealista.com, però es va enviar a una altra adreça
                return this.fetchConversationMessages(accessToken, conversationId, userEmail, 'seguros@idealista.com');
            });
    }

    function processFetchedMessages(responseData) {
        return responseData.value
            .filter(message => !message.isDraft)
            .sort((a, b) => new Date(a.sentDateTime) - new Date(b.sentDateTime))
            .map(message => {
                return {
                    Body: message.uniqueBody.content,
                    ToRecipients: message.toRecipients.map((recipient) => recipient.emailAddress.address),
                    From: message.from.emailAddress.address,
                    Subject: message.subject,
                    SentDateTime: message.sentDateTime
                };
            });
    }

    function handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);

            if (!response.ok) {
                // console.log(response);
                throw new Error(response.status);
            }

            return data;
        });
    }

    async function createMsalInstance() {
        const msalConfig = {
            auth: {
                authority: "https://login.microsoftonline.com/" + import.meta.env.VITE_APP_TENANT_ID,
                clientId: import.meta.env.VITE_APP_CLIENT_ID,
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: true,
            },
        };

        // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md
        const msalInstance = new PublicClientApplication(msalConfig);
        await msalInstance.initialize();
        return msalInstance;
    }

    return {
        fetchConversationMessages: fetchConversationMessages,
        createMsalInstance: createMsalInstance,
    };
})();

export default OutlookApiMethods;


