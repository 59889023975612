<template>
  <div>
    <!--    <span>{{ errorMessage }}</span>-->
    <!--    <span>{{ activeAccount }}</span>-->
  </div>
</template>
<script>
import * as Sentry from "@sentry/vue";
import OutlookApiMethods from "../api/OutlookApiMethods.js";

export default {
  data() {
    return {
      errorMessage: null,
      activeAccount: null,
      tokenResponse: null,
      loginRequest: {
        scopes: ['User.Read', 'Mail.Read', 'Mail.Read.Shared']
      },
    };
  },
  methods: {
    returnTokenResponse(responseData) {
      Office.context.ui.messageParent(JSON.stringify(responseData));
    },
    returnTokenErrorResponse(error) {
      this.returnTokenResponse({
        status: 'error',
        result: error,
      });
    },
    returnTokenSuccessResponse() {
      this.returnTokenResponse({
        status: 'success',
        result: this.tokenResponse
      });
    },
    async tryToAcquireTokenSilent() {
      const silentRequest = this.loginRequest;
      if (this.activeAccount) {
        silentRequest.account = this.activeAccount;
      }

      // Sentry.captureMessage('tryToAcquireTokenSilent', {
      //   level: 'info',
      // });

      await this.$parent.msalInstance.acquireTokenSilent(silentRequest)
          .then(tokenResponse => {
            this.tokenResponse = tokenResponse;
            this.returnTokenSuccessResponse();
          })
          .catch(error => {
            this.errorMessage = error;
            Sentry.captureException(error);
            // Sentry.captureMessage('user: TODO account - error acquireTokenSilent: ' + error.message);
          });
    },

    async tryToAcquireTokenInteractive() {
      // Sentry.captureMessage('tryToAcquireTokenInteractive', {
      //   level: 'info',
      // });
      await this.$parent.msalInstance.handleRedirectPromise()
          .then(async (response) => {
            if (response) {
              this.tokenResponse = response;
              this.returnTokenSuccessResponse();
            } else {
              await this.$parent.msalInstance.acquireTokenRedirect(this.loginRequest);
            }
          })
          .catch((error) => {
            this.errorMessage = error;
            Sentry.captureException(error);
            // Sentry.captureMessage('user: TODO Account - error tryToAcquireTokenInteractive: ' + error.message);
            this.returnTokenErrorResponse(error);
          });
    }
  },
  mounted() {
    Office.onReady(() => {
      // Get the current account if the user has already given the permission
      let accounts = this.$parent.msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        this.activeAccount = accounts[0];
      }

      // Sentry.captureMessage('log accounts', {
      //   level: 'info',
      //   extra: {
      //     accounts: accounts
      //   }
      // });

      // Try to get the token silently
      this.tryToAcquireTokenSilent();
      if (!this.tokenResponse) {
        // On failure, get the token interactively
        this.tryToAcquireTokenInteractive()
      }
    });
  }
}
</script>
