<template>

  <div>

    <div v-if="!loggedIn" class="p-5">
      <div v-show="!errorMessage">Loading...</div>
      <alert-message v-if="errorMessage" :message="errorMessage"></alert-message>
    </div>

    <div v-if="loggedIn">
      <div :style="'background-color: '+workspace.color"
           class="text-sm text-center font-bold leading-5 tracking-tight px-3 py-1"
           :class="{'header-inverted': workspace.invert_text_color, 'header': !workspace.invert_text_color}"
      >
        {{ workspace.name }}
      </div>

      <div class="p-3">
        <alert-message v-if="errorMessage" :message="errorMessage"></alert-message>

        <button
            class="text-white bg-gray-400 text-sm rounded-md px-4 py-2 transition duration-300 ease-in-out transform hover:bg-gray-500 active:bg-gray-600"
            v-if="showButtonToAdquireToken" @click="adquireTokenAndFetchConversation">
          Grant permissions
        </button>

        <conversation-summary v-if="messages.length>0" :messages="messages" :userEmail="userEmail"/>

        <customer-sentiment v-if="messages.length>0" :messages="messages" :userEmail="userEmail"/>

        <reply-button class="pt-3" v-if="messages.length>0"
                      :messages="messages"
                      :userEmail="userEmail"
                      :conversation-id="conversationId"/>

      </div>
    </div>

  </div>
</template>

<script>
import IdealistaApiMethods from "../api/IdealistaApiMethods.js";
import OutlookApiMethods from "../api/OutlookApiMethods.js";
import ReplyButton from "./ReplyButton.vue";
import AlertMessage from "./AlertMessage.vue";
import CustomerSentiment from "./CustomerSentiment.vue";
import ConversationSummary from "./ConversationSummary.vue";
import * as Sentry from "@sentry/vue";

export default {
  components: {AlertMessage, ReplyButton, ConversationSummary, CustomerSentiment},
  data() {
    return {
      userEmail: null,
      mailboxMail: null,
      conversationId: null,
      messages: [],

      loggedIn: false,

      showButtonToAdquireToken: false,

      // Header
      workspace: {
        name: null,
        color: null,
        invert_text_color: null,
      },

      errorMessage: null,
    }
  },

  methods: {
    idealistaLogin() {
      IdealistaApiMethods.login(this.userEmail)
          .then((data) => {
            this.workspace.name = data.workspace.name;
            this.workspace.color = data.workspace.color;
            this.workspace.invert_text_color = data.workspace.invert_text_color;
            this.loggedIn = true;
          })
          .catch(error => {
            this.errorMessage = 'There was an error retrieving your account. Please check if your account is enabled and try again';
            console.error('There was an error fetching your account', error);
            Sentry.captureException(error);
            // Sentry.captureMessage('user: ' + this.userEmail + ' - error login: ' + error.message);
          });
    },
    fetchCurrentConversation(userAccessToken) {
      OutlookApiMethods.fetchConversationMessages(
          userAccessToken,
          this.conversationId,
          this.userEmail,
          this.mailboxMail
      )
          .then(messages => this.messages = messages)
          .catch(error => {
            if (error.message == 401) {
              // console.log('trying adquireTokenAndFetchConversation');
              this.adquireTokenAndFetchConversation();
            } else {
              console.error('There was an error fetching the conversation');
              console.error(error);
              this.errorMessage = 'There has been an error reading the email message. Please try again later and get in touch if the issue persists.';
              Sentry.captureException(error);
              // Sentry.captureMessage('user: ' + this.userEmail + ' - error fetchCurrentConversation: ' + error.message);
            }
          });
    },

    adquireTokenAndFetchConversation() {
      this.showButtonToAdquireToken = false;
      const dialogLoginUrl = window.location.origin + '/auth';
      const dialogSettings = {height: 80, width: 40};

      Office.context.ui.displayDialogAsync(dialogLoginUrl, dialogSettings, (result) => {
        if (result.status === Office.AsyncResultStatus.Failed) {
          this.errorMessage = 'To use this application, please grant the required permissions.';
          // Sentry.captureMessage('user: ' + this.userEmail + ' - error displayDialogAsync_1');
          return;
        }

        let loginDialog = result.value;
        loginDialog.addEventHandler(Office.EventType.DialogMessageReceived, ({message, origin}) => {
          const messageFromDialog = JSON.parse(message);
          loginDialog.close();
          if (messageFromDialog.status === 'success') {
            this.fetchCurrentConversation(messageFromDialog.result.accessToken);
          } else {
            console.error(messageFromDialog);
            this.errorMessage = (messageFromDialog.result.errorCode === "access_denied")
                ? 'To use this application, please grant the required permissions.'
                : 'An unexpected error has occurred. Please try again later and contact us if the issue persists';
            this.showButtonToAdquireToken = (messageFromDialog.result.errorCode === "access_denied");

            // Sentry.captureMessage('user: ' + this.userEmail + ' - error displayDialogAsync_2 :' + messageFromDialog.result.errorCode);
          }
        });
      });
    },
    async getAccessTokenSingleSignOn() {
      return Office.auth.getAccessToken({
        allowSignInPrompt: true,
        allowConsentPrompt: true,
        forMSGraphAccess: true,
      });
    },
    async getMailboxMail() {
      return new Promise((resolve, reject) => {
        Office.context.mailbox.item.from.getAsync(function (result) {
          if (result.status === Office.AsyncResultStatus.Succeeded) {
            resolve(result.value.emailAddress);
          } else {
            reject(new Error("Error retrieving mailbox mail address" + result.error.message));
          }
        });
      });
    }
  },
  mounted() {
    Office.onReady(() => {
      this.userEmail = Office.context.mailbox.userProfile.emailAddress;
      this.conversationId = Office.context.mailbox.item.conversationId;

      this.idealistaLogin();

      this.getMailboxMail().then((mailboxMail) => {
        this.mailboxMail = mailboxMail;
      });


      this.getAccessTokenSingleSignOn()
          .then((accessToken) => {
            // console.log('idealista - getAccessTokenSingleSignOn success:', accessToken);
            this.fetchCurrentConversation(accessToken);
          })
          .catch((error) => {
            Sentry.captureException(error);
            // Sentry.captureMessage('user: ' + this.userEmail + ' - error getAccessTokenSingleSignOn: ' + error.message);
            console.error('idealista - getAccessTokenSingleSignOn error:', error);
            // this.errorMessage = error.message; // TODO Comment this line on deploy.
            // Attempt to get token through MSAL fallback & fetch the conversation messages
            this.adquireTokenAndFetchConversation();
          });
    });
  }
}
</script>

<style scoped>
.header {
  color: rgb(17 24 39);
}

.header-inverted {
  color: white;
}
</style>
