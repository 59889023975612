<template>
  <div class="bg-white mb-3 border">
    <div class="p-2">
        <h2 class="font-bold text-sm text-center mb-2 border-b pb-2">Resumen</h2>
        <div class="text-xs text-gray-700 mb-2 px-4 py-2 text-justify" :class="{'blur-sm': showLoading}">
            {{ summary }}
        </div>
    </div>
  </div>

  <keywords :messages="messages" :userEmail="userEmail"></keywords>

</template>

<script>
import Keywords from "./Keywords.vue";
import IdealistaApiMethods from "../api/IdealistaApiMethods.js";

export default {
  components: {Keywords},
  props: {
    userEmail: {
      type: String,
    },
    messages: {
      type: Array,
    },
  },
  data() {
    return {
      showLoading: true,
      summary: this.getDefaultValue(),
    }
  },

  methods: {
    getDefaultValue() {
      return 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.';
    },
    fetchConversationAnalysis() {
      IdealistaApiMethods.fetchConversationAnalysisSummary(this.userEmail, this.messages)
          .then((responseData) => {
            this.summary = responseData;
            this.showLoading = false;
          })
    }
  },
  mounted() {
    this.fetchConversationAnalysis();
  }
}
</script>
