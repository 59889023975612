<template>
  <div>
    <auth v-if="this.componentName==='auth'"></auth>
    <home v-if="this.componentName==='home'"></home>
  </div>
</template>

<script>
import Home from "./components/Home.vue";
import Auth from "./components/Auth.vue";
import OutlookApiMethods from "./api/OutlookApiMethods.js";

export default {
  components: {Home, Auth},
  data() {
    return {
      msalInstance: null,
      componentName: null,
    }
  },
  mounted() {
    // console.log('idealista: ','mount msalInstance');
    OutlookApiMethods.createMsalInstance()
        .then((msalInstance) => this.msalInstance = msalInstance)
        .then(() => {
          this.componentName = window.location.pathname.startsWith('/auth')
              ? 'auth'
              : 'home';
        });
  }
}
</script>
